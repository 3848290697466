<template>
  <div>
    <b-card :title="greetingText" :sub-title="daytimeText">
      <b-card-text class="mt-3 semi-bold">Shortcut</b-card-text>
      <b-button
        variant="outline-secondary text-secondary rounded-3"
        class="mr-1"
        :to="{ path: 'timesheet/leave' }"
      >
        Request Time Off
      </b-button>
      <b-button
        variant="outline-secondary text-secondary rounded-3"
        class="mr-1"
        :to="{ path: 'timesheet/overtime' }"
      >
        Request Overtime
      </b-button>
    </b-card>

    <!-- <b-row>
      <b-col md="3">
        <b-card-actions title="Total Employees" action-refresh ref="statsCJob"
          >hi</b-card-actions
        >
      </b-col>
      <b-col md="3">
        <b-card-actions
          title="Employment Status"
          action-refresh
          ref="statsCEmployee"
        >
          <div class="progress-wrapper">
            <b-card-text class="mb-0">
              {{ statsEmployee + "%" }}
            </b-card-text>
            <b-progress v-model="statsEmployee" max="100" />
          </div>

          <hr />

          <div class="d-flex justify-content-between align-items-center">
            <b-card-text class="mb-0 bold d-flex align-items-center">
              <feather-icon
                icon="CircleIcon"
                class="text-primary"
                style="margin-right: 5px"
              />
              Permanent
            </b-card-text>
            <b-card-text class="mb-0">1 - 100%</b-card-text>
          </div>
        </b-card-actions>
      </b-col>
      <b-col md="3">
        <b-card-actions
          title="Gender Diversity"
          action-refresh
          ref="statsCGender"
        >
          <div class="d-flex justify-content-between align-items-center">
            <b-card-text class="mb-0 bold d-flex align-items-center">
              <feather-icon
                icon="CircleIcon"
                class="text-primary"
                style="margin-right: 5px"
              />
              Male
            </b-card-text>
            <b-card-text class="mb-0">1 - 100%</b-card-text>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <b-card-text class="mb-0 bold d-flex align-items-center">
              <feather-icon
                icon="CircleIcon"
                class="text-danger"
                style="margin-right: 5px"
              />
              Female
            </b-card-text>
            <b-card-text class="mb-0">1 - 100%</b-card-text>
          </div>
        </b-card-actions>
      </b-col>
      <b-col md="3">
        <b-card-actions title="Who's Off" action-refresh ref="statsCOff">
          <div class="text-center">No employee is off today</div>
        </b-card-actions>
      </b-col>
    </b-row> -->
  </div>
</template>

<style>
.rounded-3 {
  border-radius: 3rem;
}

.semi-bold {
  font-weight: 550;
}

.bold {
  font-weight: 700;
}

.cardStats {
  height: 1000px !important;
}
</style>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem("sw_auth_data")),
      greetingText: "",
      daytimeText: "",
      heroImg: require("@/assets/images/svg/learning_sketching.svg"),

      statsEmployee: 0,
    };
  },

  mounted() {
    this.greeting();
    this.daytimeNow();
  },

  methods: {
    greeting() {
      const date = new Date();
      const hour = date.getHours();
      if (hour < 12) {
        this.greetingText = "Good Morning, " + this.userData.name + "!";
      } else if (hour < 18) {
        this.greetingText = "Good Afternoon, " + this.userData.name + "!";
      } else {
        this.greetingText = "Good Evening, " + this.userData.name + "!";
      }
    },

    daytimeNow() {
      // return like this It’s Tuesday, 14 February
      const date = new Date();
      const weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

      const n = weekday[date.getDay()];
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // convert month number to month name
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[month - 1];

      this.daytimeText = `It's ${n}, ${day} ${monthName} ${year}`;
    },
  },
};
</script>

<style></style>
